/*** FOUNDATION REVEAL ****/
@include foundation-reveal;

.trix-editor {
  .trix-canvas-container {
    border: $TrixEditorBorder;
  }
}

.trix-toolbar {
  background-color: $TrixToolbarBackgroundColor;
  border: $TrixToolbarBorder;
  width: auto;
  margin-bottom: 5px;

  .trix-button {
    appearance: none;
    margin: 0;
    text-align: center;
    min-width: 60px;
    color: $TrixToolbarButtonTextColor;
    background-color: $TrixToolbarButtonBackgroundColor;
    font-family: $TrixToolbarButtonFontFamily;
    padding: $TrixToolbarButtonPadding;
    font-size: $TrixToolbarButtonFontSize;
    line-height: $TrixToolbarButtonLineHeight;
    transition-duration: $TrixToolbarButtonTransitionDuration;

    &:hover {
      background-color: $TrixToolbarButtonHoverBackgroundColor;
    }

    i {
      font-size: 200%;
    }
    &[data-trix-toolbar-button='addToCart'] {
      background-color: #4caf50;
      &:hover {
        background-color: #66bb6a;
      }
    }
  }

  .trix-button:focus {
    outline: none;
  }

  .trix-menu {
    display: none;
    position: absolute;
    background-color: $TrixToolbarMenuBackgroundColor;
    z-index: 1;

    .sp-container {
      background-color: $TrixToolbarMenuBackgroundColor;
      border: 0;

      .sp-button-container {
        display: none;
      }
    }

    .trix-button {
      display: block;
      min-width: 120px;
      text-align: left;
      background-color: $TrixToolbarMenuButtonBackgroundColor;
      color: $TrixToolbarMenuButtonTextColor;
      font-family: $TrixToolbarMenuButtonFontFamily;
      padding: $TrixToolbarMenuButtonPadding;
      font-size: $TrixToolbarMenuButtonFontSize;
      line-height: $TrixToolbarMenuButtonLineHeight;
      transition-duration: $TrixToolbarMenuButtonTransitionDuration;

      &:hover {
        background-color: $TrixToolbarMenuButtonHoverBackgroundColor;
      }

      i {
        margin-right: 10px;
      }
    }

    input[type='range'] {
      appearance: none;
      width: $TrixToolbarRangeWidth;
      margin: 18px;
    }
    input[type='range']::-webkit-slider-runnable-track {
      width: $TrixToolbarRangeWidth;
      height: 5px;
      background: $TrixToolbarRangeTrackColor;
      border: none;
      border-radius: 3px;
    }
    input[type='range']::-webkit-slider-thumb {
      appearance: none;
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: $TrixToolbarRangeThumbColor;
      margin-top: -6px;
    }
    input[type='range']:focus {
      outline: none;
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
      background: $TrixToolbarRangeTrackColor;
    }

    input[type='range']::-moz-range-track {
      width: $TrixToolbarRangeWidth;
      height: 5px;
      background: $TrixToolbarRangeTrackColor;
      border: none;
      border-radius: 3px;
    }
    input[type='range']::-moz-range-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: $TrixToolbarRangeThumbColor;
    }

    input[type='range']:-moz-focusring {
      outline: 1px solid white;
      outline-offset: -1px;
    }

    input[type='range']::-ms-track {
      width: $TrixToolbarRangeTrackColor;
      height: 5px;

      /* remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
      background: transparent;

      /* leave room for the larger thumb to overflow with a transparent border */
      border-color: transparent;
      border-width: 6px 0;

      /*remove default tick marks*/
      color: transparent;
    }
    input[type='range']::-ms-fill-lower {
      background: $TrixToolbarRangeTrackColor;
      border-radius: 10px;
    }
    input[type='range']::-ms-fill-upper {
      background: $TrixToolbarRangeTrackColor;
      border-radius: 10px;
    }
    input[type='range']::-ms-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: $TrixToolbarRangeThumbColor;
    }
    input[type='range']:focus::-ms-fill-lower {
      background: $TrixToolbarRangeTrackColor;
    }
    input[type='range']:focus::-ms-fill-upper {
      background: $TrixToolbarRangeTrackColor;
    }
  }
}

.trix-menu[data-trix-toolbar-menu='fonts'] {
  max-height: 300px;
  overflow-y: scroll;
  .trix-button {
    font-size: 20px;
  }
  .trix-font-arial {
    font-family: Arial, sans-serif;
  }

  .trix-font-verdana {
    font-family: Verdana, sans-serif;
  }

  .trix-font-raleway {
    font-family: Raleway, sans-serif;
  }

  .trix-font-raleway-300 {
    font-family: Raleway, sans-serif;
    font-weight: 300;
  }

  .trix-font-raleway-900 {
    font-family: Raleway, sans-serif;
    font-weight: 900;
  }
  .trix-font-butcherman-400 {
    font-family: butchermanregular, sans-serif;
    font-weight: 400;
  }

  .trix-font-exo2-700 {
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
  }

  .trix-font-exo2-900 {
    font-family: 'Exo 2', sans-serif;
    font-weight: 900;
  }

  .trix-font-inknutantiqua-600 {
    font-family: 'Inknut Antiqua', sans-serif;
    font-weight: 600;
  }

  .trix-font-inknutantiqua-800 {
    font-family: 'Inknut Antiqua', sans-serif;
    font-weight: 800;
  }

  .trix-font-loversquarrel-400 {
    font-family: 'Lovers Quarrel', sans-serif;
    font-weight: 400;
  }

  .trix-font-molle-400 {
    font-family: Molle, sans-serif;
    font-weight: 400;
  }

  .trix-font-monoton-400 {
    font-family: Monoton, sans-serif;
    font-weight: 400;
  }

  .trix-font-montserrat-400 {
    font-family: Montserrat, sans-serif;
    font-weight: 400;
  }

  .trix-font-montserrat-700 {
    font-family: Montserrat, sans-serif;
    font-weight: 800;
  }

  .trix-font-norican-400 {
    font-family: Norican, sans-serif;
    font-weight: 400;
  }

  .trix-font-opensans-400 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  .trix-font-opensans-800 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
  }

  .trix-font-orbitron-400 {
    font-family: Orbitron, sans-serif;
    font-weight: 400;
  }

  .trix-font-orbitron-500 {
    font-family: Orbitron, sans-serif;
    font-weight: 500;
  }

  .trix-font-orbitron-700 {
    font-family: Orbitron, sans-serif;
    font-weight: 700;
  }

  .trix-font-orbitron-900 {
    font-family: Orbitron, sans-serif;
    font-weight: 900;
  }

  .trix-font-oswald-300 {
    font-family: Oswald, sans-serif;
    font-weight: 300;
  }

  .trix-font-oswald-400 {
    font-family: Oswald, sans-serif;
    font-weight: 400;
  }

  .trix-font-oswald-700 {
    font-family: Oswald, sans-serif;
    font-weight: 700;
  }

  .trix-font-ribeye-400 {
    font-family: Ribeye, cursive;
    font-weight: 400;
  }

  .trix-font-roboto-700 {
    font-family: Roboto, sans-serif;
    font-weight: 700;
  }

  .trix-font-roboto-900 {
    font-family: Roboto, sans-serif;
    font-weight: 900;
  }

  .trix-font-sourcecodepro-600 {
    font-family: 'Source Code Pro';
    font-weight: 600;
  }

  .trix-font-sourcecodepro-900 {
    font-family: 'Source Code Pro';
    font-weight: 900;
  }

  .trix-font-stoke-300 {
    font-family: Stoke, sans-serif;
    font-weight: 300;
  }

  .trix-font-stoke-400 {
    font-family: Stoke, sans-serif;
    font-weight: 400;
  }

  .trix-font-titilliumweb-600 {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
  }

  .trix-font-titilliumweb-900 {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 900;
  }

  .trix-font-unifrakturmaguntia-400 {
    font-family: UnifrakturMaguntia, cursive;
    font-weight: 400;
  }

  .trix-font-vastshadow-400 {
    font-family: 'Vast Shadow', sans-serif;
    font-weight: 400;
  }
}

#trix-upload-to-cart {
  .progress-wrapper {
    width: 100%;
    background-color: grey;
    height: 50px;
  }

  .progress-bar {
    background-color: red;
    height: 50px;
    transition-duration: 0.3s;
  }
}

.trix-modals {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(63, 63, 63, 0.66);
  z-index: 1000;
  & > * {
    display: none;
    box-shadow: 10px 10px 20px #333;
  }
  & a.close-reveal-modal {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    border-radius: 16px;
  }
  .reveal {
    text-align: center;
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    img.trix-preview {
      box-shadow: rgba(0, 0, 0, 0.5) 0 0 5px;
      margin: 8px 0;
    }
    input.trix-add-qty {
      display: inline;
      width: 80px;
    }
    button#confirm-preview {
      color: white;
      background-color: #4caf50;
      &:hover {
        background-color: #66bb6a;
      }
    }
  }
}
