// Editor Styles
$TrixToolbarBorder: 1px solid #404040 !default;
$TrixEditorBorder: 10px solid #404040 !default;


// Button Styles
$TrixToolbarButtonTextColor: white !default;
$TrixToolbarMenuButtonTextColor: white !default;
$TrixToolbarBackgroundColor: #404040 !default;
$TrixToolbarMenuBackgroundColor: #404040 !default;
$TrixToolbarButtonFontFamily: Arial, sans-serif !default;
$TrixToolbarMenuButtonFontFamily: Arial, sans-serif !default;
$TrixToolbarButtonBackgroundColor: transparent !default;
$TrixToolbarMenuButtonBackgroundColor: transparent !default;
$TrixToolbarButtonPadding: 13px !default;
$TrixToolbarMenuButtonPadding: 13px !default;
$TrixToolbarButtonFontSize: 12px !default;
$TrixToolbarMenuButtonFontSize: 12px !default;
$TrixToolbarButtonHoverBackgroundColor: #494949 !default;
$TrixToolbarMenuButtonHoverBackgroundColor: #494949 !default;
$TrixToolbarButtonTransitionDuration: 0.3s !default;
$TrixToolbarMenuButtonTransitionDuration: 0.3s !default;
$TrixToolbarButtonLineHeight: 1.25rem !default;
$TrixToolbarMenuButtonLineHeight: 1.25rem !default;

// Range Styles
$TrixToolbarRangeWidth: 200px !default;
$TrixToolbarRangeTrackColor: #888888;
$TrixToolbarRangeThumbColor: #c0c0c0;
