body { background-color: #ffffff; }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-6322);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-6322);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-6320);
color: #ffffff;
padding: 10px;

 }
.MES2 {
background-color: var(--clr-6320);
color: #ffffff;
padding: 10px;

h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: #ffffff;
 }
h1.MEC2 { font-size: 10px; }
h1.MEC2 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC2 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC2 { font-size: 8.8px; }
h2.MEC2 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC2 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC2 { font-size: 8px; }
h3.MEC2 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC2 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC2 { font-size: 7.2px; }
h4.MEC2 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC2 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC2 { font-size: 6.8px; }
h5.MEC2 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC2 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC2 { font-size: 6.4px; }
h6.MEC2 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC2 { @media #{$large-up} { font-size: 18px; }; }
 }
a.MEC2 { color: #ffffff;
 }
cite.MEC2{
color: #ffffff;
}
/* Home Banner:3 */
.MES3 {
background-color: var(--clr-6321);
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://custombarmats.com.au/img/313/17');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:145px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 360px;};
padding: 0;

 }
.MES3 {
background-color: var(--clr-6321);
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://custombarmats.com.au/img/313/17');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:145px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 360px;};
padding: 0;

h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
h1.MEC3 { font-size: 22.5px; }
h1.MEC3 { @media #{$medium-up} { font-size: 60px; }; }
h1.MEC3 { @media #{$large-up} { font-size: 80px; }; }
h2.MEC3 { font-size: 19.8px; }
h2.MEC3 { @media #{$medium-up} { font-size: 52.5px; }; }
h2.MEC3 { @media #{$large-up} { font-size: 70px; }; }
h3.MEC3 { font-size: 18px; }
h3.MEC3 { @media #{$medium-up} { font-size: 45px; }; }
h3.MEC3 { @media #{$large-up} { font-size: 60px; }; }
h4.MEC3 { font-size: 16.2px; }
h4.MEC3 { @media #{$medium-up} { font-size: 37.5px; }; }
h4.MEC3 { @media #{$large-up} { font-size: 50px; }; }
h5.MEC3 { font-size: 15.3px; }
h5.MEC3 { @media #{$medium-up} { font-size: 30px; }; }
h5.MEC3 { @media #{$large-up} { font-size: 40px; }; }
h6.MEC3 { font-size: 14.4px; }
h6.MEC3 { @media #{$medium-up} { font-size: 27px; }; }
h6.MEC3 { @media #{$large-up} { font-size: 36px; }; }
 }
cite.MEC3{
color: #ffffff;
}
/* Footer:4 */
.MES4 {
background-color: var(--clr-6322);
color: #ffffff;
padding: 20px 0;

 }
.MES4 {
background-color: var(--clr-6322);
color: #ffffff;
padding: 20px 0;

h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
h1.MEC4 { font-size: 17.5px; }
h1.MEC4 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC4 { font-size: 15.4px; }
h2.MEC4 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC4 { font-size: 14px; }
h3.MEC4 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC4 { font-size: 12.6px; }
h4.MEC4 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC4 { font-size: 11.9px; }
h5.MEC4 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC4 { font-size: 11.2px; }
h6.MEC4 { @media #{$large-up} { font-size: 18px; }; }
 }
a.MEC4 { color: #ffffff;
 }
cite.MEC4{
color: #ffffff;
}
/* primary:5 */
.MES5 {
background-color: var(--clr-6322);
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 19.6px;
@media #{$medium-up} {
font-size: 20.8px;
};
@media #{$large-up} {
font-size: 24px;
};
padding: 10px 25px;

@media #{$medium-up} {
padding: 15px 10px;

}
@media #{$large-up} {
padding: 20px;

}
 }
/* Shade 1:6 */
.MES6 {
background-color: var(--clr-6323);
color: #000000;
padding: 20px 10px 10px 10px;

@media #{$medium-up} {
padding: 30px 30px 20px 30px;

}
 }
.MES6 {
background-color: var(--clr-6323);
color: #000000;
padding: 20px 10px 10px 10px;

@media #{$medium-up} {
padding: 30px 30px 20px 30px;

}
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #000000;
 }
h1.MEC6 { font-size: 22.5px; }
h2.MEC6 { font-size: 19.8px; }
h3.MEC6 { font-size: 18px; }
h4.MEC6 { font-size: 16.2px; }
h5.MEC6 { font-size: 15.3px; }
h6.MEC6 { font-size: 14.4px; }
 }
cite.MEC6{
color: #000000;
}
/* Page Banner:7 */
.MES7 {
background-color: var(--clr-6321);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://custombarmats.com.au/img/313/17');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 80px 10px 10px 10px;

@media #{$medium-up} {
padding: 120px 10px 40px 10px;

}
 }
.MES7 {
background-color: var(--clr-6321);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://custombarmats.com.au/img/313/17');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 80px 10px 10px 10px;

@media #{$medium-up} {
padding: 120px 10px 40px 10px;

}
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: #ffffff;
 }
h1.MEC7 { font-size: 10px; }
h1.MEC7 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC7 { @media #{$large-up} { font-size: 80px; }; }
h2.MEC7 { font-size: 8.8px; }
h2.MEC7 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC7 { @media #{$large-up} { font-size: 70px; }; }
h3.MEC7 { font-size: 8px; }
h3.MEC7 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC7 { @media #{$large-up} { font-size: 60px; }; }
h4.MEC7 { font-size: 7.2px; }
h4.MEC7 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC7 { @media #{$large-up} { font-size: 50px; }; }
h5.MEC7 { font-size: 6.8px; }
h5.MEC7 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC7 { @media #{$large-up} { font-size: 40px; }; }
h6.MEC7 { font-size: 6.4px; }
h6.MEC7 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC7 { @media #{$large-up} { font-size: 36px; }; }
 }
cite.MEC7{
color: #ffffff;
}
/* Secondary:8 */
.MES8 {
background-color: var(--clr-6324);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

 }
/* Primary 50%:9 */
.MES9 {
background-color: var(--clr-6325);
color: #ffffff;
padding: 20px;

 }
.MES9 {
background-color: var(--clr-6325);
color: #ffffff;
padding: 20px;

h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
h1.MEC9 { font-size: 10px; }
h1.MEC9 { @media #{$medium-up} { font-size: 24px; }; }
h1.MEC9 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC9 { font-size: 8.8px; }
h2.MEC9 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC9 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC9 { font-size: 8px; }
h3.MEC9 { @media #{$medium-up} { font-size: 18px; }; }
h3.MEC9 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC9 { font-size: 7.2px; }
h4.MEC9 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC9 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC9 { font-size: 6.8px; }
h5.MEC9 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC9 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC9 { font-size: 6.4px; }
h6.MEC9 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC9 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC9{
color: #ffffff;
}
/* Bordergrey:10 */
.MES10 {
background-color: #ffffff;
color: #000000;
padding: 10px;

@media #{$medium-up} {
padding: 20px 29px 20px 20px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-6323);
 }
.MES10 {
background-color: #ffffff;
color: #000000;
padding: 10px;

@media #{$medium-up} {
padding: 20px 29px 20px 20px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-6323);
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: var(--clr-6322);
 }
h1.MEC10 { font-size: 15px; }
h1.MEC10 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC10 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC10 { font-size: 13.2px; }
h2.MEC10 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC10 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC10 { font-size: 12px; }
h3.MEC10 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC10 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC10 { font-size: 10.8px; }
h4.MEC10 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC10 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC10 { font-size: 10.2px; }
h5.MEC10 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC10 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC10 { font-size: 9.6px; }
h6.MEC10 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC10 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC10{
color: #000000;
}
/* New Style:11 */
.MES11 {
background-color: transparent;
color: #000000;
padding: 10px;

 }
.MES11 {
background-color: transparent;
color: #000000;
padding: 10px;

h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #000000;
 }
h1.MEC11 { font-size: 22.5px; }
h1.MEC11 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC11 { font-size: 19.8px; }
h2.MEC11 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC11 { font-size: 18px; }
h3.MEC11 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC11 { font-size: 16.2px; }
h4.MEC11 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC11 { font-size: 15.3px; }
h5.MEC11 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC11 { font-size: 14.4px; }
h6.MEC11 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC11{
color: #000000;
}
/* Grey background:13 */
.MES13 {
background-color: var(--clr-6323);
padding: 10px;

 }
.MES13 {
background-color: var(--clr-6323);
padding: 10px;

 }
/* Top Menu:14 */
nav.me-Menu.MES14 {
& .menu-item.MEC14, & .menu-item.MEC14 > div.MEC14{ & > a.MEC14{color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.8px;
}text-transform: uppercase;
}
 &:hover > a.MEC14{color: #dcdcdc;
}
 }
&.horizontal > .menu-item.MEC14 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC14 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC14 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC14 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Red banner:15 */
.MES15 {
background-color: #ef5350;
color: #ffffff;
 }
.MES15 {
background-color: #ef5350;
color: #ffffff;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
